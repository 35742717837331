import trainingQualificationService from '@/modules/auth/services/TrainingQualificationService';
import type AvailableTrainingQualification from '@/types/AvailableTrainingQualification';
import type TrainingQualification from '@/types/TrainingQualification';

export default function useTrainingQualification() {
  const availableTrainingQualifications = ref<AvailableTrainingQualification[]>([]);
  const trainingQualifications = ref<TrainingQualification[]>([]);
  const fetchingAvailable = ref(false);
  const fetching = ref(false);
  const creating = ref(false);

  const { showSnackbar } = useSnackbar();

  const getAvailableTrainingQualifications = async (): AvailableTrainingQualification[] => {
    if (fetchingAvailable.value) return;

    availableTrainingQualifications.value = [];
    fetchingAvailable.value = true;

    const { data } = await trainingQualificationService.getAvailableTrainingQualifications();

    availableTrainingQualifications.value = data.value || [];
    fetchingAvailable.value = false;

    return availableTrainingQualifications.value;
  };

  const getTrainingQualifications = async (): TrainingQualification[] => {
    if (fetching.value) return;

    trainingQualifications.value = [];
    fetching.value = true;

    const { data } = await trainingQualificationService.getTrainingQualifications();

    trainingQualifications.value = data.value || [];
    fetching.value = false;

    return trainingQualifications.value;
  };

  const createTrainingQualifications = async (form: TrainingQualification[]) => {
    if (creating.value) return;

    creating.value = true;
    const { data, error } = await trainingQualificationService.createTrainingQualifications(form);
    creating.value = false;

    if (error.value) {
      showSnackbar({
        text: error.value,
        state: false,
      });
    } else {
      // @NOTE: API returns the whole `/me` object rather than the created object
      trainingQualifications.value = data.value?.data?.qualifications || [];
      return trainingQualifications.value;
    }
  };

  return {
    availableTrainingQualifications,
    trainingQualifications,
    fetchingAvailable,
    fetching,
    creating,

    getAvailableTrainingQualifications,
    getTrainingQualifications,
    createTrainingQualifications,
  };
}
