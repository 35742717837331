import Model from '@/core/models/Model';
import AvailableTrainingQualification from '@/modules/auth/models/AvailableTrainingQualification';
import TrainingQualification from '@/modules/auth/models/TrainingQualification';
import type { TrainingQualification as ITrainingQualification } from '@/modules/auth/types/TrainingQualification';
import type { Service } from '@/types/Service';

class TrainingQualificationService {
  getAvailableTrainingQualifications() {
    return useAsyncData<Service[]>(
      'available-training-qualifications',
      async () => {
        return Model.processResponse(await AvailableTrainingQualification.get()) as Service[];
      },
      { server: false }
    );
  }

  getTrainingQualifications() {
    return useAsyncData<Service[]>(
      'training-qualifications',
      async () => {
        return Model.processResponse(await TrainingQualification.get()) as Service[];
      },
      { server: false }
    );
  }

  async createTrainingQualifications(form: ITrainingQualification[]) {
    const { data, error, execute } = useSubmit();
    await execute('worker/qualifications', {
      method: 'POST',
      body: {
        qualifications: form,
      },
    });

    return {
      data,
      error,
    };
  }
}

const trainingQualificationService = new TrainingQualificationService();
export default trainingQualificationService;
