<script lang="ts" setup>
import { AppTypography, AppTextField, AppDatePicker, AppButton, AppFileUpload } from '@/components/base';
import type { AvailableTrainingQualification } from '@/types/AvailableTrainingQualification';
import type { TrainingQualification } from '@/modules/auth/types/TrainingQualification';
import { DESCRIPTION_ONLY, FILE_ONLY } from '@/modules/auth/utils/enums/TrainingQualificationRules';
import { helpers, requiredIf } from '@vuelidate/validators';
import { isEmpty } from 'lodash';

const props = withDefaults(
  defineProps<{
    rule: AvailableTrainingQualification;
    form: TrainingQualification;

    disabled?: boolean;
  }>(),
  {
    disabled: false,
  }
);

const { VALIDATIONS } = useValidation();
const rules = computed(() => {
  return {
    issued_at: {
      required: VALIDATIONS.required('Issued at'),
    },
    media: {
      requiredIf: requiredIf(() => props.rule.requirements === FILE_ONLY && !props.form.attachment),
    },
    description: {
      requiredIf: requiredIf(() => props.rule.requirements === DESCRIPTION_ONLY),
    },
  };
});
const {
  v$,
  handleSubmit,
  getErrors: getValidationError,
} = useForm({
  form: props.form,
  rules: rules,
});

const errors = computed(() => {
  return getValidationError();
});

const isEnabled = computed((): boolean => {
  return props.form.enabled;
});

const validate = async (): boolean => {
  if (!props.form.enabled) {
    await v$.value.$reset();

    return true;
  }

  return await v$.value.$validate();
};

const removeAttachment = (): void => {
  props.form.attachment = '';
};

defineExpose({
  validate,
});
</script>
<template>
  <v-card v-if="rule" flat class="qualification-form">
    <v-card-title class="d-flex justify-space-between px-0">
      <AppTypography class="px-4 py-5" element="span" type="headline-1">{{ rule.name }}</AppTypography>
      <v-checkbox v-model="form.enabled" :disabled="disabled" color="primary" hide-details data-testid="enable">
      </v-checkbox>
    </v-card-title>
    <v-card-text v-if="isEnabled" class="qualification-form__fields pb-0">
      <v-row class="qualification-form__form mb-5">
        <v-col cols="12">
          <AppTypography type="subheadline-1" class="mb-2">Issue Date</AppTypography>
          <AppDatePicker
            v-model="form.issued_at"
            :error-messages="errors.issued_at"
            :disabled="disabled"
            placeholder="DD/MM/YYYY"
            hide-details="auto"
            append-icon=""
            label=""
            data-testid="issue-date"
          />
        </v-col>
        <v-col v-if="rule.requirements === FILE_ONLY" cols="12">
          <AppTypography type="subheadline-1" class="mb-2">Upload File</AppTypography>
          <AppFileUpload
            v-model="form.media"
            :value="form.attachment"
            :error-messages="errors.media"
            :disabled="disabled"
            hide-details="auto"
            data-testid="file"
            @removed="removeAttachment()"
          />
        </v-col>
        <v-col v-if="rule.requirements === DESCRIPTION_ONLY" cols="12">
          <AppTypography type="subheadline-1" class="mb-2">Description</AppTypography>
          <AppTextField
            v-model="form.description"
            placeholder="Description"
            :error-messages="errors.description"
            :disabled="disabled"
            hide-details="auto"
            data-testid="description"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style lang="scss" scoped>
.qualification-form {
  border-radius: 16px;
}
</style>
